var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "validation-observer",
        { ref: "simpleRules" },
        [
          _c(
            "form-component",
            {
              attrs: {
                copyData: _vm.corporationCopy,
                addressField: "address",
                copyAddress: _vm.addressCopy,
                showHeader: true,
                showStatus: _vm.formData.id,
              },
              on: {
                "update:copyData": function ($event) {
                  _vm.corporationCopy = $event
                },
                "update:copy-data": function ($event) {
                  _vm.corporationCopy = $event
                },
                "update:copyAddress": function ($event) {
                  _vm.addressCopy = $event
                },
                "update:copy-address": function ($event) {
                  _vm.addressCopy = $event
                },
                save: _vm.validationForm,
              },
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function () {
                    return [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.formData.id
                              ? _vm.formData.name
                              : "Add New Corporation"
                          ) +
                          "\n      "
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
              model: {
                value: _vm.formData,
                callback: function ($$v) {
                  _vm.formData = $$v
                },
                expression: "formData",
              },
            },
            [
              _c(
                "b-form",
                { attrs: { slot: "body" }, slot: "body" },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { md: "4", sm: "6" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Corporation Name",
                                "label-for": "corporationName",
                              },
                            },
                            [
                              _c("validation-provider", {
                                attrs: { name: "Corporation Name", rules: "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("b-form-input", {
                                          attrs: {
                                            id: "corporationName",
                                            placeholder: "",
                                          },
                                          model: {
                                            value: _vm.corporationCopy.name,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.corporationCopy,
                                                "name",
                                                $$v
                                              )
                                            },
                                            expression: "corporationCopy.name",
                                          },
                                        }),
                                        _c(
                                          "small",
                                          { staticClass: "text-danger" },
                                          [_vm._v(_vm._s(errors[0]))]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "NPI Number",
                                        "label-for": "NPINumber",
                                      },
                                    },
                                    [
                                      _c("validation-provider", {
                                        attrs: {
                                          name: "NPI Number",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    id: "NPINumber",
                                                    placeholder: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.corporationCopy
                                                        .npiNumber,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.corporationCopy,
                                                        "npiNumber",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "corporationCopy.npiNumber",
                                                  },
                                                }),
                                                _c(
                                                  "small",
                                                  {
                                                    staticClass: "text-danger",
                                                  },
                                                  [_vm._v(_vm._s(errors[0]))]
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Short Code",
                                        "label-for": "shortCode",
                                      },
                                    },
                                    [
                                      _c("validation-provider", {
                                        attrs: {
                                          name: "Short Code",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("b-form-input", {
                                                  directives: [
                                                    {
                                                      name: "upper",
                                                      rawName: "v-upper",
                                                    },
                                                  ],
                                                  attrs: {
                                                    id: "shortCode",
                                                    placeholder: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.corporationCopy.code,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.corporationCopy,
                                                        "code",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "corporationCopy.code",
                                                  },
                                                }),
                                                _c(
                                                  "small",
                                                  {
                                                    staticClass: "text-danger",
                                                  },
                                                  [_vm._v(_vm._s(errors[0]))]
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              attrs: { label: "Tax ID", "label-for": "taxID" },
                            },
                            [
                              _c("validation-provider", {
                                attrs: { name: "Tax ID", rules: "required" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("b-form-input", {
                                          attrs: {
                                            id: "taxID",
                                            placeholder: "",
                                          },
                                          model: {
                                            value: _vm.corporationCopy.taxId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.corporationCopy,
                                                "taxId",
                                                $$v
                                              )
                                            },
                                            expression: "corporationCopy.taxId",
                                          },
                                        }),
                                        _c(
                                          "small",
                                          { staticClass: "text-danger" },
                                          [_vm._v(_vm._s(errors[0]))]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { md: "4", sm: "6" } },
                        [
                          _c("address-component", {
                            model: {
                              value: _vm.addressCopy,
                              callback: function ($$v) {
                                _vm.addressCopy = $$v
                              },
                              expression: "addressCopy",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }