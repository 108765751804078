var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c(
            "tabs-component",
            { staticClass: "pl-2 pt-2", attrs: { pills: "" } },
            [
              _c(
                "b-tab",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function () {
                        return [_vm._v(" Details")]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("DetailsCC", {
                    on: { save: _vm.save },
                    model: {
                      value: _vm.formData,
                      callback: function ($$v) {
                        _vm.formData = $$v
                      },
                      expression: "formData",
                    },
                  }),
                ],
                1
              ),
              _c(
                "can",
                { attrs: { I: "read", a: "ClaimRules" } },
                [
                  _c(
                    "b-tab",
                    {
                      attrs: { disabled: _vm.isNew },
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function () {
                            return [_vm._v(" Fee schedules")]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [_c("FeeSchedulesCC")],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-tab",
                {
                  attrs: { disabled: _vm.isNew },
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function () {
                        return [_vm._v(" Corporation profiles")]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("CorporationProfilesCC", {
                    model: {
                      value: _vm.formData,
                      callback: function ($$v) {
                        _vm.formData = $$v
                      },
                      expression: "formData",
                    },
                  }),
                ],
                1
              ),
              _c("b-tab", {
                attrs: { disabled: _vm.isNew },
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function () {
                      return [_vm._v(" Activity & Notes")]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }