var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table-component", {
    ref: "tableComponent",
    attrs: {
      "items-repo": _vm.overrideRepo,
      "copy-data": _vm.overrideCopy,
      "sidebar-data": _vm.override,
      "sidebar-open": _vm.sidebar,
      "is-add-edit-sidbar": true,
      fields: _vm.fields,
      "load-params": { CorporationId: _vm.$route.params.itemData },
      "is-data-from-model": false,
      validate: "true",
      "do-save": true,
      width: "30%",
      "get-by-id-on-edit-filter": function () {
        return false
      },
    },
    on: {
      "update:copyData": function ($event) {
        _vm.overrideCopy = $event
      },
      "update:copy-data": function ($event) {
        _vm.overrideCopy = $event
      },
      "update:sidebarData": function ($event) {
        _vm.override = $event
      },
      "update:sidebar-data": function ($event) {
        _vm.override = $event
      },
      "update:sidebarOpen": function ($event) {
        _vm.sidebar = $event
      },
      "update:sidebar-open": function ($event) {
        _vm.sidebar = $event
      },
      save: _vm.onSave,
      "on-delete": _vm.onDeleted,
    },
    scopedSlots: _vm._u([
      {
        key: "cell(priceDetail)",
        fn: function (data) {
          return [
            data.item.priceDetails.length > 0
              ? _c(
                  "div",
                  _vm._l(data.item.priceDetails, function (item, index) {
                    return _c("div", { key: index }, [
                      _c("div", [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              item.modifier ? item.modifier.code : "NONE"
                            ) +
                            "\n        "
                        ),
                      ]),
                    ])
                  }),
                  0
                )
              : _vm._e(),
          ]
        },
      },
      {
        key: "cell(facilityPrice)",
        fn: function (data) {
          return [
            data.item.priceDetails.length > 0
              ? _c(
                  "div",
                  _vm._l(data.item.priceDetails, function (item, index) {
                    return _c("div", { key: index }, [
                      _c("div", [
                        _vm._v(
                          "\n          " +
                            _vm._s(item.facilityPrice) +
                            "\n        "
                        ),
                      ]),
                    ])
                  }),
                  0
                )
              : _vm._e(),
          ]
        },
      },
      {
        key: "cell(nonFacilityPrice)",
        fn: function (data) {
          return [
            data.item.priceDetails.length > 0
              ? _c(
                  "div",
                  _vm._l(data.item.priceDetails, function (item, index) {
                    return _c("div", { key: index }, [
                      _c("div", [
                        _vm._v(
                          "\n          " +
                            _vm._s(item.nonFacilityPrice) +
                            "\n        "
                        ),
                      ]),
                    ])
                  }),
                  0
                )
              : _vm._e(),
          ]
        },
      },
      {
        key: "cell(inOutNetwork)",
        fn: function (data) {
          return [
            _vm._v(
              "\n    " +
                _vm._s(
                  data.item.inOutNetwork == _vm.ContractType.InNetwork
                    ? "In Network"
                    : data.item.inOutNetwork == _vm.ContractType.OutNetwork
                    ? "Out Of Network"
                    : ""
                ) +
                "\n  "
            ),
          ]
        },
      },
      {
        key: "body",
        fn: function () {
          return [
            _c("procedur-mod-price-sidebar", {
              attrs: {
                "cpt-id": _vm.overrideCopy.cptId,
                "price-details": _vm.overrideCopy.priceDetails,
                "facility-separated-price": true,
                "rate-type": "Fixed",
              },
              on: {
                "update:cptId": function ($event) {
                  return _vm.$set(_vm.overrideCopy, "cptId", $event)
                },
                "update:cpt-id": function ($event) {
                  return _vm.$set(_vm.overrideCopy, "cptId", $event)
                },
                "update:priceDetails": function ($event) {
                  return _vm.$set(_vm.overrideCopy, "priceDetails", $event)
                },
                "update:price-details": function ($event) {
                  return _vm.$set(_vm.overrideCopy, "priceDetails", $event)
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "afterDescription",
                  fn: function () {
                    return [
                      _c(
                        "b-form-group",
                        { attrs: { label: "Contract Type" } },
                        [
                          _c("b-form-select", {
                            attrs: { options: Object.values(_vm.ContractType) },
                            model: {
                              value: _vm.overrideCopy.contractType,
                              callback: function ($$v) {
                                _vm.$set(_vm.overrideCopy, "contractType", $$v)
                              },
                              expression: "overrideCopy.contractType",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }