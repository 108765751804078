var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("table-component", {
        attrs: {
          isDataFromModel: true,
          sidebarData: _vm.selectedProfile,
          fields: _vm.fields,
          isAddEditSidbar: false,
          copyData: _vm.selectedProfileCopy,
          "add-edit-link": {
            name: "practices-details-view-profile",
            params: {
              itemData: _vm.selectedProfile.id ? _vm.selectedProfile.id : "new",
            },
          },
          "get-by-id-on-edit-filter": function () {
            return false
          },
          "delete-filter": function () {
            return false
          },
        },
        on: {
          "update:sidebarData": function ($event) {
            _vm.selectedProfile = $event
          },
          "update:sidebar-data": function ($event) {
            _vm.selectedProfile = $event
          },
          "update:copyData": function ($event) {
            _vm.selectedProfileCopy = $event
          },
          "update:copy-data": function ($event) {
            _vm.selectedProfileCopy = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "Add",
            fn: function () {
              return [_c("span")]
            },
            proxy: true,
          },
          {
            key: "cell(profileName)",
            fn: function (data) {
              return [
                _c("b", [_vm._v(_vm._s(data.item.name))]),
                _c("br"),
                _c("span", { staticClass: "grey-text smaller-text" }, [
                  _vm._v("SC: " + _vm._s(data.item.code)),
                ]),
              ]
            },
          },
          {
            key: "cell(insurance.isIpa)",
            fn: function (data) {
              return [
                _vm._v(
                  "\n      " +
                    _vm._s(data.item.insurance.isIpa ? "Yes" : "No") +
                    "\n    "
                ),
              ]
            },
          },
        ]),
        model: {
          value: _vm.formData.profiles,
          callback: function ($$v) {
            _vm.$set(_vm.formData, "profiles", $$v)
          },
          expression: "formData.profiles",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }